<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
  >
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>
            <v-icon
              medium
              left
            >
              mdi mdi-tune-variant
            </v-icon>
            {{ $t('buttons.content-creator.filters') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <div style="height: 500px; overflow-y: auto">
        <v-card-title>
          {{ $t('content-creator.post-type') }}
        </v-card-title>
        <v-card-text
          class="d-flex justify-space-between text-align-center"
        >
          <v-row>
            <v-col
              v-for="kind in kinds"
              :key="kind.text"
              cols="12"
              class="my-0 mx-0 py-0 "
              sm="6"
              md="6"
            >
              <v-checkbox
                v-model="selectedKinds"
                :value="kind.value"
                :label="kind.text"
                multiple
                hide-details
                color="success"
              >
                <template v-slot:label>
                  <div class="d-flex align-center m-0 p-0">
                    <v-avatar height="20">
                      <v-img
                        :src="kind.icon"
                        width="20"
                        height="20"
                        class="timeline-avatar m-0 p-0"
                        contain
                      />
                    </v-avatar>
                    <span class="mr-2">{{ kind.text }}</span>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider
          light
          class="my-7 divider"
        />
        <template>
          <v-card-title class="py-0">
            {{ $t('content-creator.topic-type') }}
          </v-card-title>
          <v-card-text
            class="d-flex justify-space-between text-align-center"
          >
            <v-row class="mt-0 pt-0">
              <template
                v-for="topicGroup in topics"
                class="mb-2"
              >
                <span
                  v-if="topicGroup.items?.length > 0"
                  :key="topicGroup.header"
                  class="col-12 pb-0 pt-6"
                >{{ topicGroup.header }}</span>
                <v-col
                  v-for="topic in topicGroup.items"
                  :key="topic.value"
                  cols="12"
                  class="my-0 mx-0 py-0 "
                  sm="6"
                  md="6"
                >
                  <v-checkbox
                    v-if="topic.value"
                    v-model="selectedTopics"
                    :value="topic.value"
                    :label="topic.text"
                    multiple
                    hide-details
                    color="success"
                  >
                    <template v-slot:label>
                      <div class="d-flex align-center m-0 p-0">
                        <span class="mr-2">{{ topic.text }}</span>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </template>
      </div>
      <v-divider
        light
        class="my-2 divider"
      />
      <v-card-actions class="d-flex justify-space-between">
        <div>
          <v-btn
            color="primary"
            class="clear-all-filters-btn"
            text
            @click="clearAllFilters"
          >
            {{ $t('buttons.content-creator.clear-all') }}
          </v-btn>
        </div>
        <div>
          <v-btn
            color="gray lighten-1"
            class="ml-2 close-btn px-0"
            text
            @click="closeDialog"
          >
            {{ $t('buttons.content-creator.close') }}
          </v-btn>
          <v-btn
            color="primary"
            class="apply-filters-btn"
            text
            @click="applyFilters"
          >
            <v-icon
              left
              dark
            >
              mdi-refresh
            </v-icon>
            {{ $t('buttons.content-creator.apply') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    topics: {
      type: Object,
      default: () => ({})
    },
    filter: {
      type: Object,
      default: () => ({})
    },
    kinds: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialog: true,
      selectedKinds: this.filter.selectedKinds,
      selectedTopics: this.filter.selectedTopics
    }
  },
  methods: {
    closeDialog () {
      this.dialog = false
      this.$emit('close')
    },

    clearAllFilters () {
      this.selectedKinds = []
      this.selectedTopics = []
    },
    applyFilters () {
      this.$emit('applyFilters', {
        selectedKinds: this.selectedKinds,
        selectedTopics: this.selectedTopics,
        numberOfAppliedFilters: this.selectedKinds.length + this.selectedTopics.length
      })
      this.closeDialog()
    }
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
 .clear-all-filters-btn, .close-btn, .apply-filters-btn {
   font-size: 10px;
 }
}
</style>
