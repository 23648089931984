<template>
  <div class="ma-4">
    <span class="font-weight-bold">
      {{ $t('content-creator.limits.headline') }}
    </span>
    <ul>
      <li>{{ $t('content-creator.limits.generated-texts') }} <strong>{{ generationStats.textCount }}</strong> {{ $t('content-creator.limits.of') }} <strong>{{ limits.text }}</strong></li>
      <li>{{ $t('content-creator.limits.generated-images') }} <strong>{{ generationStats.imageCount }}</strong> {{ $t('content-creator.limits.of') }} <strong>{{ limits.image }}</strong></li>
    </ul>
  </div>
</template>
<script>
import GET_GENERATION_STATS from './queries/getGenerationStats.gql'
import featureMixin from '@/mixins/feature'

export default {
  mixins: [featureMixin],

  data () {
    return {
      limits: {
        text: 0,
        image: 0
      },
      generationStats: {
        textCount: 0,
        imageCount: 0
      }
    }
  },

  async mounted () {
    const feature = await this.getFeature(this.featureNames.CONTENT_CREATOR)
    const { maxTextGenerationsPerMonth, aiImageLimit } = feature.config
    const unlimited = this.$t('content-creator.limits.unlimited')
    this.limits.text = maxTextGenerationsPerMonth === -1 ? unlimited : maxTextGenerationsPerMonth
    this.limits.image = aiImageLimit === -1 ? unlimited : aiImageLimit
  },

  apollo: {
    generationStats: {
      query: GET_GENERATION_STATS
    }
  }
}
</script>
