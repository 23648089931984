<template>
  <v-btn
    class="py-6 d-flex flex-row justify-start"
    @click="connectToFacebook"
  >
    <v-img
      :src="KindTypeIcon.facebookPost"
      width="30"
      height="30"
      class="mr-2 flex-grow-0"
    />
    {{ $t('buttons.social-media.connect-facebook') }}
  </v-btn>
</template>

<script>
import { SocialMedia } from '../../enums/SocialMedia'
import {
  facebookSDKInitialized,
  initFbSDK,
  loginToFacebook
} from '@/lib/facebook'
import { KindTypeIcon } from '../../enums/KindType'

export default {
  props: {
    connectionWrapper: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      KindTypeIcon
    }
  },
  methods: {
    async connectToFacebook () {
      await this.connectionWrapper('Facebook', async () => {
        if (!facebookSDKInitialized()) {
          await initFbSDK()
        }
        const login = await loginToFacebook()
        return {
          accessToken: login.authResponse.accessToken,
          userId: login.authResponse.userID,
          type: SocialMedia.FACEBOOK
        }
      })
    }
  }
}
</script>
