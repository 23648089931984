<template>
  <div class="h-full d-flex flex-column">
    <v-card-text>
      <div class="pa-0 d-flex flex-column gap-1">
        <div class="share-title">
          {{ $t('social-media.share.title') }}
        </div>

        <v-list>
          <v-list-item
            v-for="page in pages"
            :key="page.id"
            class="d-flex px-0"
          >
            <v-list-item-avatar
              tile
              size="30"
              class="mr-4"
            >
              <v-img :src="KindTypeIcon[TypeToKind[page.type]]" />
            </v-list-item-avatar>
            <v-skeleton-loader
              v-if="page.loading"
              type="card-heading"
              class="page-loader w-full pl-0 pr-16"
            />
            <v-list-item-content v-if="!page.loading">
              <v-list-item-title
                class="page-name"
                :loading="page.loading"
              >
                {{ page.name }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="!page.loading">
              <v-switch
                v-model="page.selected"
                :loading="page.loading"
                class="pr-4"
                @change="selectionChanged"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>

        <div
          v-if="platformErrors.length"
          class="d-flex flex-column gap-1"
        >
          <v-alert
            v-for="platform in platformErrors"
            :key="platform"
            type="error"
            dense
            tile
            text
            class="mb-0"
          >
            {{ $t('social-media.share.error', { platform }) }}
          </v-alert>
        </div>
      </div>
    </v-card-text>

    <v-card-actions :style="{'display': isMobile ? 'block' : ''}">
      <v-spacer />

      <v-tooltip
        top
        :disabled="selectedPages.length > 0"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
          >
            <v-btn
              color="primary"
              class="continue-button"
              :class="{'col-12': isMobile}"
              :text="!isMobile"
              :outlined="isMobile"
              :disabled="selectedPages.length === 0"
              @click="$emit('next')"
            >
              <span>{{ $t('labels.continue') }}</span>
            </v-btn>
          </div>
        </template>
        <span>
          {{ $t('social-media.alert.select-page') }}
        </span>
      </v-tooltip>
    </v-card-actions>

    <v-card-text v-if="allPagesNotConnected">
      <v-divider />

      <div class="d-flex align-center gap-2 mt-4 flex-column flex-md-row">
        <div>
          <h3 class="py-1 link-account-title">
            {{ $t('social-media.share.link-account.title') }}
          </h3>
          <div class="link-account-subtitle">
            {{ $t('social-media.share.link-account.subtitle') }}
          </div>
        </div>
        <SocialMediaConnect
          :connect-media-dialog="connectMediaDialog"
          @close="close"
        />
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { KindTypeIcon, TypeToKind } from '../enums/KindType'
import GET_LINKEDIN_PAGES from '../queries/getLinkedinOrganizations.gql'
import GET_FACEBOOK_PAGES from '../queries/getFacebookPages.gql'
import GET_SOCIAL_MEDIA_CONNECTIONS from '../queries/getSocialMediaConnections.gql'

import SocialMediaConnect from './connect/SocialMediaConnect.vue'

export default {
  components: { SocialMediaConnect },
  props: {
    textKind: {
      type: String,
      required: true
    },
    selectedPlatforms: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      KindTypeIcon,
      TypeToKind,
      socialMediaConnections: [],
      linkedinPages: [],
      metaPages: [],
      selectedPages: [],
      platformErrors: [],
      socialMediaConnectionsLoaded: false,
      connectMediaDialog: false
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    allPagesNotConnected () {
      const loadingFinished = this.linkedinPages.every(({ loading }) => !loading) &&
        this.metaPages.every(({ loading }) => !loading)
      return this.pages.length !== 3 && loadingFinished
    },
    pages () {
      const pages = [
        ...this.metaPages.map((page) => ({
          ...page,
          type: 'FACEBOOK'
        })),
        ...this.metaPages
          .filter((page) => this.socialMediaConnections.includes('INSTAGRAM') && page.instagram_business_account)
          .map(({ instagram_business_account: { id, username }, loading }) => ({
            id,
            name: username,
            loading,
            type: 'INSTAGRAM'
          })),
        ...this.linkedinPages.map(({ urn, name, loading }) => ({
          id: urn,
          name,
          loading,
          type: 'LINKEDIN'
        }))
      ]
      return pages.map(page => ({
        ...page,
        selected: this.selectedPlatforms.length > 0
          ? this.selectedPlatforms.some((type) => type === page.type)
          : pages.length === 1 || this.textKind === TypeToKind[page.type]
      }))
    }
  },
  watch: {
    socialMediaConnectionsLoaded () {
      if (!this.pages || this.pages.length === 0) {
        this.openConnectMediaDialog()
      }
    },
    pages () {
      this.selectionChanged()
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    openConnectMediaDialog () {
      this.connectMediaDialog = true
    },
    selectionChanged () {
      this.selectedPages = this.pages.filter(({ selected }) => selected)
      this.$emit('selectedPages', this.selectedPages)
    }
  },
  apollo: {
    socialMediaConnections: {
      query: GET_SOCIAL_MEDIA_CONNECTIONS,
      update ({ socialMediaConnections } = { socialMediaConnections: [] }) {
        if (socialMediaConnections.includes('LINKEDIN') && this.linkedinPages.length === 0) {
          this.linkedinPages = [{ isActive: true, loading: true, id: 'linkedInLoading' }]
        }
        if (socialMediaConnections.includes('FACEBOOK') && this.metaPages.length === 0) {
          this.metaPages = [{ isActive: true, loading: true, id: 'facebookLoading' }]
        }
        this.socialMediaConnectionsLoaded = true
        return socialMediaConnections
      }
    },
    linkedinPages: {
      query: GET_LINKEDIN_PAGES,
      skip () {
        return !this.socialMediaConnections.includes('LINKEDIN')
      },
      error () {
        if (!this.platformErrors.includes('LinkedIn')) {
          this.platformErrors.push('LinkedIn')
        }
      },
      update (data) {
        return data.linkedinPages.filter(({ isActive }) => isActive)
      }
    },
    metaPages: {
      query: GET_FACEBOOK_PAGES,
      skip () {
        return !this.socialMediaConnections.includes('FACEBOOK')
      },
      error () {
        if (!this.platformErrors.includes('Facebook')) {
          this.platformErrors.push('Facebook')
        }
      },
      update (data) {
        return data.metaPages.filter(({ isActive }) => isActive)
      }
    }
  }
}
</script>

<style>
.page-loader .v-skeleton-loader__heading {
  margin: 0;
}

.share-title, .page-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.link-account-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.link-account-subtitle {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.continue-button {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
</style>
