<template>
  <div class="d-flex gap-1">
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedDate"
          prepend-inner-icon="mdi-calendar"
          outlined
          readonly
          dense
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        :value="localDate"
        :min="new Date().toISOString().slice(0, 10)"
        :locale="$auth.user.locale"
        :first-day-of-week="1"
        @input="dateChanged"
      />
    </v-menu>
    <v-menu
      ref="menu"
      v-model="showTimePicker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formattedTime"
          prepend-inner-icon="mdi-clock-time-four-outline"
          readonly
          outlined
          dense
          hide-details
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-time-picker
        v-if="showTimePicker"
        v-model="time"
        full-width
        format="24hr"
        :min="minTime"
        @click:minute="timeChanged"
        @click:hour="hourChanged"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: () => new Date().toISOString()
    }
  },
  data () {
    return {
      showDatePicker: false,
      showTimePicker: false,
      time: ''
    }
  },
  computed: {
    minTime () {
      return this.isToday ? new Date().toLocaleTimeString() : ''
    },
    isToday () {
      return this.localDate === new Date().toISOString().slice(0, 10)
    },
    localDate () {
      return new Date(this.value).toISOString().slice(0, 10)
    },
    formattedDate () {
      return new Date(this.value).toLocaleDateString()
    },
    formattedTime () {
      return this.time
    }
  },
  watch: {
    value (newValue) {
      this.time = this.formatTime(newValue)
    }
  },
  created () {
    this.time = this.formatTime(this.value)
  },
  methods: {
    formatTime (dateTime) {
      if (this.isToday && new Date(dateTime) < new Date()) {
        return new Date().toTimeString().slice(0, 5)
      }
      return new Date(dateTime).toTimeString().slice(0, 5)
    },
    updateDateTime (date, time) {
      const dateObj = new Date(date)
      const [hours, minutes] = time.split(':').map(Number)
      dateObj.setHours(hours, minutes)
      return dateObj.toISOString()
    },
    dateChanged (date) {
      const newDateTime = this.updateDateTime(date, this.time)
      this.$emit('input', newDateTime)
      this.showDatePicker = false
    },
    hourChanged () {
      const newDateTime = this.updateDateTime(this.localDate, this.time)
      this.$emit('input', newDateTime)
    },
    timeChanged () {
      const newDateTime = this.updateDateTime(this.localDate, this.time)
      this.$emit('input', newDateTime)
      this.showTimePicker = false
    }
  }
}
</script>

<style scoped>
::v-deep .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), ::v-deep .v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-radius: 0;
}
</style>
