<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        v-if="showInfo"
        v-bind="{ attrs, on }"
      />
      <slot
        v-else
      />
    </template>

    <v-card class="d-flex flex-column">
      <v-card-title class="pa-0">
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>{{ $t('upgrade-info.title') }}</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="mt-8">
        <div>
          {{ $t('alerts.upselling.upgrade.0') }}
          <!-- Use wrapper span to track the event as @click is not called on <router-link> -->
          <span @click="$tracking.event('UpgradeInfoDialog', 'Clicked', 'Upgrade')">
            <router-link
              class="pointer font-weight-bold"
              :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
            >
              {{ $t('alerts.upselling.upgrade.link') }}
            </router-link>
          </span>
          {{ $t('alerts.upselling.upgrade.1') }}
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          text
          @click="dialog = false"
        >
          {{ $t('buttons.content-creator.close') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          :to="{ name: 'Settings', params: { section: 'contract' }, query: { showUpgrade: '1' } }"
          @click="$tracking.event('UpgradeInfoDialog', 'Clicked', 'Upgrade')"
        >
          {{ $t('upgrade-info.title') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false
    }
  }
}
</script>
