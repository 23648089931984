<template>
  <v-alert
    class="my-4 mx-sm-4 w-xs-full"
    icon="mdi-information-slab-circle"
    text
    type="info"
  >
    {{ infoMessage }}
    <template v-if="linkMessage">
      <br>
      <a
        class="link"
        :title="linkMessage"
        @click="navigate()"
        v-text="linkMessage"
      />
    </template>
  </v-alert>
</template>

<script>
export default {
  props: {
    infoMessage: {
      type: String,
      default: null
    },
    linkMessage: {
      type: String,
      default: null
    },
    linkPath: {
      type: String,
      default: null
    }
  },
  methods: {
    navigate () {
      this.$router.push(this.linkPath)
    }
  }

}
</script>

<style scoped>

  .link {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
