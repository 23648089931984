/**
 * Set query parameters to a URL
 * Handles adding query parameters to a URL, even if it already has some.
 * @param {string} url - The URL to append the query parameters to
 * @param {object} params - The query parameters to append
 * @returns {string} The URL with the query parameters appended
 */
export default function setQueryParameters (url, params) {
  const urlObj = new URL(url)
  Object.keys(params).forEach(key => urlObj.searchParams.set(key, params[key]))
  return urlObj.toString()
}
