<template>
  <div class="h-full full-size-container">
    <v-card
      :outlined="$vuetify.breakpoint.smAndUp"
      :rounded="$vuetify.breakpoint.smAndUp ? 'md' : '0'"
      class="px-2 pt-2 px-md-4"
    >
      <div class="d-flex justify-space-between">
        <v-tabs
          v-model="section"
          show-arrows
        >
          <v-tab
            replace
            to="#creator"
          >
            {{ $t('content-creator.tabs.creator') }}
          </v-tab>
          <v-tab
            v-if="isSchedulePostFeatureActive"
            replace
            to="#scheduled-posts"
          >
            {{ $t('content-creator.tabs.scheduled-posts') }}
            <v-badge
              v-if="savedScheduledContentCounter > 0"
              color="red"
              :content="savedScheduledContentCounter"
              offset-x="0"
            >
              <v-icon
                icon="$vuetify"
                size="x-large"
              />
            </v-badge>
          </v-tab>
          <v-tab
            replace
            to="#history"
          >
            {{ $t('content-creator.tabs.saved-content') }}
            <v-badge
              v-if="savedContentCounter > 0"
              color="red"
              :content="savedContentCounter"
              offset-x="0"
            >
              <v-icon
                icon="$vuetify"
                size="x-large"
              />
            </v-badge>
          </v-tab>
        </v-tabs>
      </div>
      <v-divider />
      <v-tabs-items
        v-model="section"
        class="pt-4"
        touchless
      >
        <v-tab-item value="creator">
          <Creator
            v-if="section === 'creator'"
            :key="section"
            :active-tab="section"
            @contentSaved="savedContentCounter++"
            @savedScheduledContent="savedScheduledContentCounter++"
          />
        </v-tab-item>
        <v-tab-item
          v-if="isSchedulePostFeatureActive"
          value="scheduled-posts"
        >
          <SavedContentTimeline
            :key="section"
            :content-type="ContentType.SCHEDULED_CONTENT"
            :update-saved-contents="savedScheduledContentCounter"
          />
        </v-tab-item>
        <v-tab-item value="history">
          <SavedContentTimeline
            :key="section"
            :content-type="ContentType.SAVED_CONTENT"
            :update-saved-contents="savedContentCounter"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>
<script>
import Creator from './creator'
import SavedContentTimeline from './creator/SavedContentTimeline.vue'
import { FilterType } from './creator/enums/FilterType'
import KindType from './creator/enums/KindType'
import feature from '@/mixins/feature'
import { ContentType } from '@/modules/contentCreator/creator/enums/ContentType'

export default {
  components: {
    SavedContentTimeline,
    Creator
  },
  mixins: [feature],
  data () {
    return {
      kind: KindType.facebookPost,
      section: '',
      savedContentCounter: 0,
      savedScheduledContentCounter: 0,
      contentType: ContentType.SAVED_CONTENT
    }
  },
  computed: {
    isSchedulePostFeatureActive () {
      const feature = this.getFeature(this.featureNames.CONTENT_CREATOR)
      if (!feature || !feature.config) {
        return false
      }

      return feature.config.schedulePost
    },
    ContentType () {
      return ContentType
    }
  },
  watch: {
    section () {
      if (this.section === FilterType.ALL) {
        this.resetContentCounter()
      }
    }
  },
  methods: {
    resetContentCounter () {
      this.savedScheduledContentCounter = 0
      this.savedContentCounter = 0
    }
  }
}
</script>
