<template>
  <div class="pa-md-6 creator-text-box w-full">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          class="edit"
          v-on="on"
          @click="handleClick"
        >
          mdi-pencil-outline
        </v-icon>
      </template>
      <span>{{ $t('buttons.content-creator.edit') }}</span>
    </v-tooltip>

    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="measuringDiv"
      :style="{ 'width': currentWidth - 70 + 'px' }"
      class="creator-text-box-content pointer"
      @click="handleClick"
      v-html="parsedContent"
    />

    <v-fade-transition>
      <div
        v-if="isLoading"
        class="loader"
      >
        <ProgressCircular />
      </div>
    </v-fade-transition>
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
import { marked } from 'marked'
import DOMPurify from 'dompurify'
import ProgressCircular from '@/components/ProgressCircular'

export default {
  components: {
    ProgressCircular
  },
  props: {
    // could be markdown
    content: {
      type: String,
      default: ''
    },
    // indicates if the content is markdown
    isMarkdown: {
      type: Boolean,
      default: false
    },
    currentWidth: {
      type: Number,
      default: 250
    },
    recalculateHeight: {
      type: Number,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      parsedContent: ''
    }
  },

  watch: {
    recalculateHeight () {
      this.calculateHeight()
    },

    async content () {
      await this.parseContent()
      this.$nextTick(() => {
        this.calculateHeight()
      })
    }
  },

  async mounted () {
    await this.parseContent()
    this.$nextTick(() => {
      this.calculateHeight()
    })
  },

  methods: {
    async parseContent () {
      if (this.isMarkdown) {
        const parsed = await marked.parse(this.content)
        // Currently we just sanitize. We could think about allowing only certain tags.
        this.parsedContent = DOMPurify.sanitize(parsed)
      } else {
        this.parsedContent = this.content
      }
    },

    calculateHeight () {
      if (this.$refs.measuringDiv) {
        this.$emit('currentHeight', this.$refs.measuringDiv.offsetHeight)
      }
    },

    handleClick () {
      this.$emit('handleClick')
    }
  }
}
</script>
<style scoped>
.creator-text-box {
  overflow: auto;
  scrollbar-width: thin;
  height: 100%;

}

@media (max-width: 960px) {
  .creator-text-box {
    padding: 10px 20px;
  }
  .edit {
    display: none;
  }

  .creator-text-box-content {
    font-size: 12px;
    padding: 10px;
  }
}

.creator-text-box-content {
  position: relative;
  white-space: pre-wrap;
  font-size: 14px;
  min-height: 300px;
}

.edit {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.pointer {
  cursor: pointer;
}

.loader {
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
