<template>
  <div>
    <Carousel
      v-for="part in parts"
      :key="part + getContent(part).length /* to force re-render */"
      :kind="kind"
      :part="part"
      :has-at-least-one-image="hasImage"
      :choices="getContent(part)"
      :is-loading="loadingState[part]"
      @openEditTextDialog="openEditTextDialog"
      @change="handleChange"
    />
  </div>
</template>

<script>
import Carousel from './Carousel'
import { partMapper } from './lib/partMapper'

export default {
  components: {
    Carousel
  },
  props: {
    kind: {
      type: String,
      default: ''
    },
    generatedContent: {
      type: Object,
      default: () => { }
    },
    hasImage: {
      type: Boolean,
      default: false
    },
    loadingState: {
      type: Object,
      default: () => ({
        text: false,
        image: false
      })
    }
  },
  computed: {
    parts () {
      return partMapper[this.kind].filter(this.getContent)
    }
  },
  methods: {
    getContent (part) {
      return {
        text: () => this.generatedContent?.text?.choices,
        title: () => this.generatedContent?.title?.choices,
        image: () => this.generatedContent?.image?.choices
      }[part]()
    },
    openEditTextDialog (index) {
      this.$emit('openEditTextDialog', index)
    },
    handleChange (data) {
      this.$emit('change', { part: data.part, content: data.content })
    }
  }
}
</script>
