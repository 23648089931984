<template>
  <div class="d-flex justify-start mt-5 align-center">
    <v-checkbox
      v-if="displayCheckbox"
      v-model="post.selected"
      color="primary"
      hide-details
      class="ma-0 pa-0 align-self-center"
    />
    <div class="ml-1 mr-2">
      <v-img
        :src="KindTypeIcon[TypeToKind[post.type]]"
        width="36"
        height="36"
        contain
      />
    </div>
    <div class="d-flex justify-space-between scheduled-at">
      <span class="ml-1">
        <v-icon
          size="24"
          class="mr-1"
        >mdi-calendar-month</v-icon> {{ formatDate(post.scheduledAt) }}
        <v-icon
          size="24"
          class="ml-2 mr-1"
        >mdi-clock</v-icon> {{ formatTime(post.scheduledAt) }}
      </span>
    </div>
  </div>
</template>

<script>
import { KindTypeIcon, TypeToKind } from '@/modules/contentCreator/creator/enums/KindType'
import { formatDate, formatTime } from '../lib/date'

export default {
  props: {
    post: {
      type: Object,
      required: true
    },
    displayCheckbox: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  computed: {
    KindTypeIcon () {
      return KindTypeIcon
    },
    TypeToKind () {
      return TypeToKind
    }
  },
  methods: {
    formatDate,
    formatTime
  }
}
</script>

<style scoped>
.scheduled-at {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>
