<template>
  <v-card class="card">
    <v-card-title class="pa-0 title">
      <v-img
        :src="platformData.icon"
        width="30"
        height="30"
        class="mr-2 flex-grow-0 "
      />
      <span class="platform">{{ platformData.name }}</span>

      <template v-if="onePageConnected">
        <span
          v-for="page in pages"
          :key="page[platformData.id]"
          class="page"
        >
          ({{ page.name }})
        </span>
      </template>
      <template v-else>
        <v-radio-group

          v-model="selectedPage"
          class="mt-0 ml-5 py-2 page"
          hide-details
        >
          <v-radio
            v-for="page in pages"
            :key="page[platformData.id]"
            :label="page.name"
            :value="page[platformData.id]"
          />
        </v-radio-group>
      </template>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-alert
        v-if="!hasActivePage"
        type="warning"
      >
        {{ $t('social-media.alert.select-page') }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import { KindTypeIcon } from '../../enums/KindType'
import ADD_FACEBOOK_PAGE_TO_USER from '@/modules/contentCreator/creator/queries/addFacebookPageToUser.gql'
import ADD_LINKEDIN_PAGE_TO_USER from '@/modules/contentCreator/creator/queries/addLinkedinOrganizationToUser.gql'
import GET_LINKEDIN_PAGES from '../../queries/getLinkedinOrganizations.gql'
import GET_FACEBOOK_PAGES from '../../queries/getFacebookPages.gql'

const platforms = {
  LINKEDIN: {
    name: 'LinkedIn',
    icon: KindTypeIcon.linkedinPost,
    id: 'urn',
    save: 'addLinkedinPageToUser'
  },
  FACEBOOK: {
    name: 'Facebook',
    icon: KindTypeIcon.facebookPost,
    id: 'id',
    save: 'addFacebookPageToUser'
  },
  INSTAGRAM: {
    name: 'Instagram',
    icon: KindTypeIcon.instagramPost,
    id: 'id',
    save: 'addFacebookPageToUser'
  }
}

export default {
  props: {
    platform: {
      type: String,
      required: true
    },
    pages: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedPage: this.getSelectedPage()
    }
  },
  computed: {
    onePageConnected () {
      return this.pages.length === 1
    },
    platformData () {
      return platforms[this.platform]
    },
    hasActivePage () {
      return this.pages.some(page => page.isActive)
    }
  },
  watch: {
    selectedPage (value) {
      if (value) {
        this[this.platformData.save](value)
      }
    },
    pages: {
      handler () {
        this.selectedPage = this.getSelectedPage()
      },
      deep: true
    }
  },
  methods: {
    getSelectedPage () {
      return this.pages.find(page => page.isActive)?.[platforms[this.platform].id]
    },

    async addFacebookPageToUser (pageId) {
      await this.$apollo.mutate({
        mutation: ADD_FACEBOOK_PAGE_TO_USER,
        variables: {
          input: { pageId }
        },
        refetchQueries: [{ query: GET_FACEBOOK_PAGES }]
      })
    },

    async addLinkedinPageToUser (organizationUrn) {
      await this.$apollo.mutate({
        mutation: ADD_LINKEDIN_PAGE_TO_USER,
        variables: {
          input: { organizationUrn }
        },
        refetchQueries: [{ query: GET_LINKEDIN_PAGES }]
      })
    }
  }
}
</script>

<style scoped>
.card {
  width: 100%;
  min-height: 50px;
  min-width: 64px;
  padding-left: 16px;
  align-content: center;
  background-color: #f5f5f5;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: none !important;
  border-radius: 4px !important;
}

.title {
  justify-content: start;
}

.platform, .page {
  font-style: normal;
  line-height: 18px;
}
.platform {
  font-size: 18px;
  font-weight: 500;
}

.page {
  font-weight: 400;
  margin-left: 8px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
}
</style>
