<template>
  <div class="h-full d-flex flex-column">
    <v-card-text :class="{ 'px-0' : isMobile }">
      <div>
        <h3
          v-if="isUpdateForScheduledPost"
          class="post-reschedule-title"
        >
          {{ $t('social-media.post-reschedule') }}
        </h3>
        <v-radio-group
          v-else
          v-model="isScheduledPost"
          class="isScheduledPost"
          hide-details
          :disabled="loading"
        >
          <v-radio
            class="mb-6"
            :value="false"
          >
            <template v-slot:label>
              <h3
                :class="{ 'radio-button-active': !isScheduledPost }"
                class="radio-button"
              >
                {{ $t('social-media.post-now') }}
              </h3>
            </template>
          </v-radio>
          <v-radio :value="true">
            <template v-slot:label>
              <h3
                :class="{ 'radio-button-active': isScheduledPost }"
                class="radio-button"
              >
                {{ $t('social-media.post-schedule') }}
              </h3>
            </template>
          </v-radio>
        </v-radio-group>

        <v-expand-transition>
          <BlurredBackdropUpselling
            v-if="isScheduledPost"
            :is-blurred="!isSchedulePostEnabled"
          >
            <div class="gap-1 py-2 d-flex flex-column">
              <span
                v-if="!isUpdateForScheduledPost"
                class="schedule-text"
              >
                {{ $t('social-media.schedule.title') }} <br><br>
                {{ $t('social-media.schedule.subtitle') }}
              </span>
              <span
                v-else
                class="schedule-text"
              >{{ $t('social-media.reschedule.subtitle') }}</span>
              <DateTimeInput
                v-if="!hasCustomSchedules"
                v-model="date"
              />
              <v-expand-transition v-else>
                <div class="gap-1 d-flex flex-column">
                  <div
                    v-for="page in pages"
                    :key="page.id"
                    class="flex-row gap-1 d-flex align-center"
                  >
                    <v-img
                      :src="KindTypeIcon[TypeToKind[page.type]]"
                      width="36"
                      height="36"
                    />
                    <DateTimeInput
                      v-model="customSchedules[page.type].date"
                    />
                  </div>
                </div>
              </v-expand-transition>
              <div v-if="pages.length > 1">
                <UpgradeInfoDialog :show-info="!isCustomSchedulePostEnabled">
                  <template v-slot="{ on, attrs }">
                    <v-btn
                      color="primary"
                      style="text-transform: none;"
                      class="px-0"
                      text
                      plain
                      dense
                      v-bind="attrs"
                      v-on="on"
                      @click="toggleCustomSchedules"
                    >
                      {{ hasCustomSchedules ? $t('social-media.schedule.common-schedule') : $t('social-media.schedule.custom-schedule') }}
                    </v-btn>
                  </template>
                </UpgradeInfoDialog>
              </div>
            </div>
          </BlurredBackdropUpselling>
        </v-expand-transition>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="!isUpdateForScheduledPost"
        text
        class="back-btn"
        :disabled="loading"
        @click="$emit('back')"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-btn
        v-else
        text
        :disabled="loading"
        class="close-btn"
        @click="$emit('close')"
      >
        {{ $t('labels.close') }}
      </v-btn>

      <v-spacer />

      <v-btn
        color="primary"
        text
        class="post-btn"
        :loading="loading"
        :disabled="isScheduledPost && !isSchedulePostEnabled"
        @click="handleOnPostClick"
      >
        {{ isScheduledPost ? $t('buttons.social-media.post-schedule') : $t('buttons.social-media.post-now') }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import POST_TO_SOCIAL_MEDIA from '../queries/postToSocialMedia.gql'
import UPDATE_SOCIAL_MEDIA_POST from '../queries/updateSocialMediaPost.gql'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import DateTimeInput from './DateTimeInput.vue'
import { KindTypeIcon, TypeToKind } from '../enums/KindType'
import feature from '@/mixins/feature'
import BlurredBackdropUpselling from '@/components/BlurredBackdropUpselling.vue'
import UpgradeInfoDialog from '@/components/UpgradeInfoDialog.vue'
import DELETE_SOCIAL_MEDIA_POST from '@/modules/contentCreator/creator/queries/deleteSocialMediaPosts.gql'
import DELETE_SAVED_CONTENT from '@/modules/contentCreator/creator/queries/deleteSavedContent.gql'

// Wednesday at 11:00 is the time when the audience is most active
const BEST_TIME_TO_POST = {
  day: 3, // Wednesday
  hour: 11
}

export default {
  components: {
    DateTimeInput, BlurredBackdropUpselling, UpgradeInfoDialog
  },
  mixins: [feature],
  props: {
    pages: {
      type: Array,
      default: () => []
    },
    content: {
      type: Object,
      default: () => {}
    },
    isUpdateForScheduledPost: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      KindTypeIcon,
      TypeToKind,
      isScheduledPost: false,
      date: null,
      customSchedules: {},
      hasCustomSchedules: false,
      loading: false
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isSchedulePostEnabled () {
      return this.getFeature(this.featureNames.CONTENT_CREATOR).config.schedulePost
    },
    isCustomSchedulePostEnabled () {
      return this.getFeature(this.featureNames.CONTENT_CREATOR).config.customSchedulePost
    }
  },
  watch: {
    isScheduledPost (val) {
      this.$emit('isScheduledPost', val)
    },
    pages: {
      handler () {
        // Reset custom schedules when pages change
        this.hasCustomSchedules = false
        this.customSchedules = {}
      },
      deep: true
    }
  },

  created () {
    this.isScheduledPost = !!this.content.scheduledAt
    this.date = this.isScheduledPost ? this.getScheduledPostDate() : this.getNextBestMomentToPost()
  },
  methods: {
    async handleOnPostClick () {
      if (this.isUpdateForScheduledPost) {
        if (!this.hasCustomSchedules) {
          await this.updateSocialMediaPosts()
        } else {
          await this.handleCustomScheduledPost()
        }
        this.$emit('updatePostSuccess', this.getPosts())
      } else {
        await this.post(this.getPosts())
        if (this.isScheduledPost) {
          this.$emit('updatePostSuccess', this.getPosts())
        }
      }

      this.$tracking.event('Content Creator', 'Clicked', 'Share', this.content.textKind)
    },

    async handleCustomScheduledPost () {
      try {
        const groupedItems = this.groupByDateTime()
        const originalScheduledAt = new Date(this.content.scheduledAt).toISOString()
        let processedCount = 0

        for (const data of groupedItems) {
          const currentScheduledAt = new Date(data.scheduledAt).toISOString()
          if (currentScheduledAt !== originalScheduledAt) {
            await this.post([data])
            await this.deleteSocialMediaPosts(data.platforms)
            processedCount++
          }
        }

        if (processedCount === groupedItems.length) {
          await this.deleteSavedContent()
        }

        this.$emit('savedScheduledContent')
      } catch (error) {
        showSnackbarMessage('error', this.$t('social-media.alert.something-went-wrong'))
      }
    },

    async deleteSocialMediaPosts (platforms) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_SOCIAL_MEDIA_POST,
          variables: {
            input: {
              ids: this.content.socialMediaPosts
                .filter(post => platforms.includes(post.type))
                .map(({ id }) => id)
            }
          }
        })
      } catch (error) {}
    },
    async deleteSavedContent () {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_SAVED_CONTENT,
          variables: {
            input: {
              id: this.content.id
            }
          }
        })
      } catch (err) {}
    },
    async updateSocialMediaPosts () {
      this.loading = true
      try {
        for (const post of this.content.socialMediaPosts) {
          await this.$apollo.mutate({
            mutation: UPDATE_SOCIAL_MEDIA_POST,
            variables: {
              input: {
                postId: post.id,
                scheduledAt: this.date
              }
            }
          })
          this.$tracking.event('Content Creator', 'Edited', 'Updated Social Media Posts')
        }
      } catch (error) {
        showSnackbarMessage('error', this.$t('social-media.alert.post-update-error'))
      } finally {
        this.loading = false
      }
    },
    async post (posts) {
      this.loading = true
      try {
        const posted = []
        for (const { scheduledAt, platforms } of posts) {
          const { data } = await this.$apollo.mutate({
            mutation: POST_TO_SOCIAL_MEDIA,
            variables: {
              input: {
                message: this.content.text,
                imageLink: this.content.image,
                platforms: platforms,
                ...(this.isScheduledPost && { scheduledAt }),
                imageSource: this.content.imageSource,
                textKind: this.content.textKind,
                topicId: this.content.topicId
              }
            }
          })
          posted.push(...data.posts)
        }
        this.$emit('success', posted)
      } catch (error) {
        showSnackbarMessage('error', this.$t('alerts.social-media.post-to-social-network.error'))
      } finally {
        this.loading = false
      }
    },
    getPosts () {
      if (!this.isScheduledPost) {
        return [{ platforms: this.pages.map(page => page.type) }]
      } else if (!this.hasCustomSchedules) {
        return [{ scheduledAt: this.date, platforms: this.pages.map(page => page.type) }]
      } else {
        return this.groupByDateTime()
      }
    },
    groupByDateTime () {
      const groupedByDate = Object.entries(this.customSchedules).reduce((acc, [type, { date }]) => {
        if (!acc[date]) {
          acc[date] = []
        }
        acc[date].push(type)
        return acc
      }, {})

      return Object.entries(groupedByDate).map(([scheduledAt, platforms]) => ({
        scheduledAt,
        platforms
      }))
    },
    toggleCustomSchedules () {
      if (!this.isCustomSchedulePostEnabled) {
        return
      }
      if (!this.hasCustomSchedules) {
        this.pages.forEach(page => {
          this.$set(this.customSchedules, page.type, { date: this.date })
        })
        this.hasCustomSchedules = true
      } else {
        this.hasCustomSchedules = false
        this.customSchedules = {}
      }
    },

    getScheduledPostDate () {
      return new Date(this.content.socialMediaPosts[0].scheduledAt).toISOString()
    },

    getNextBestMomentToPost () {
      const now = new Date()
      const dayOfWeek = now.getDay()
      const daysUntilNextWednesday = (BEST_TIME_TO_POST.day - dayOfWeek + 7) % 7 || 7
      const nextWednesday = new Date(now)
      nextWednesday.setDate(now.getDate() + daysUntilNextWednesday)
      nextWednesday.setHours(BEST_TIME_TO_POST.hour, 0, 0, 0)
      return nextWednesday.toISOString()
    }
  }
}
</script>

<style>
.isScheduledPost .v-radio {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.post-reschedule-title, .radio-button {
  font-size: 16px !important;
  line-height: 22px;
  font-style: normal;
}

.post-reschedule-title {
  font-weight: 600;
}

.radio-button {
  font-weight: 400;
}

.radio-button-active {
  font-weight: 800;
}

.post-btn, .close-btn, .back-btn {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.schedule-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
</style>
