<template>
  <div class="social-media-dialog text-center">
    <v-dialog
      v-model="dialog"
      width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      content-class="social-media-dialog"
      :persistent="step !== 3"
    >
      <template
        v-slot:activator="{ on, attrs }"
      >
        <v-tooltip
          :disabled="!disabled"
          top
        >
          <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <div
              v-bind="tooltipAttrs"
              v-on="tooltipOn"
            >
              <slot
                name="button"
                v-bind="{ attrs, on }"
              >
                <v-btn
                  v-if="isPostButtonVisible"
                  color="primary"
                  class="w-xs-full ma-md-4"
                  v-bind="attrs"
                  :disabled="disabled"
                  v-on="on"
                >
                  {{ $t('content-creator.post.button') }}
                </v-btn>
              </slot>
            </div>
          </template>
          <span>{{ $t('buttons.content-creator.tooltip.disable-post') }}</span>
        </v-tooltip>
      </template>

      <v-card class="social-media-dialog d-flex flex-column">
        <v-card-title class="pa-0">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title
              v-if="isScheduledPost"
              class="dialog-title"
            >
              {{ $t('content-creator.reschedule.title') }}
            </v-toolbar-title>
            <v-toolbar-title
              v-else
              class="dialog-title"
            >
              {{ $t('content-creator.post.title') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="dialog=false"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-stepper
          v-if="dialog"
          v-model="step"
          alt-labels
          flat
          color="primary"
          elevation="0"
        >
          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="py-0"
            >
              <SharingStep
                :text-kind="content.textKind"
                :selected-platforms="selectedPlatforms"
                @close="dialog=false"
                @next="step = 2"
                @selectedPages="selectedPages = $event"
              />
            </v-stepper-content>

            <v-stepper-content
              step="2"
              class="py-0"
            >
              <ScheduleStep
                :pages="selectedPages"
                :content="content"
                :is-update-for-scheduled-post="isUpdateForScheduledPost"
                @close="dialog=false"
                @back="step = 1"
                @success="handleSuccess"
                @updatePostSuccess="handleUpdatePostSuccess"
                @isScheduledPost="isScheduledPost = $event"
              />
            </v-stepper-content>
            <v-stepper-content step="3">
              <SuccessStep
                :is-scheduled-post="isScheduledPost"
                :posts="posts"
                :is-update-for-scheduled-post="isUpdateForScheduledPost"
                @close="dialog = false"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SharingStep from './SharingStep.vue'
import ScheduleStep from './ScheduleStep.vue'
import SuccessStep from './SuccessStep.vue'
import { ContentType } from '../enums/ContentType'
import { PostType } from '@/modules/contentCreator/creator/enums/PostType'

export default {
  components: { SharingStep, ScheduleStep, SuccessStep },
  props: {
    content: {
      type: Object,
      default: () => ({})
    },
    contentType: {
      type: String,
      default: ''
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isScheduledPost: this.contentType === ContentType.SCHEDULED_CONTENT,
      selectedPages: [],
      posts: [],
      dialog: false,
      step: 1
    }
  },
  computed: {
    isPostButtonVisible () {
      const postTypes = [PostType.facebookPost, PostType.instagramPost, PostType.linkedinPost]
      return postTypes.includes(this.content.textKind)
    },
    selectedPlatforms () {
      return this.content.socialMediaPosts?.map(({ type }) => type) || []
    },
    isUpdateForScheduledPost () {
      return this.isUpdate && this.contentType === ContentType.SCHEDULED_CONTENT
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.resetState()
      }
    }
  },
  methods: {
    resetState () {
      this.isScheduledPost = false
      this.selectedPages = []
      this.posts = []
      this.step = this.isUpdateForScheduledPost ? 2 : 1
    },
    handleUpdatePostSuccess (scheduledPosts) {
      this.posts = scheduledPosts
      this.step = 3
      this.$emit('savedScheduledContent')
    },
    handleSuccess (posts) {
      this.posts = posts
      this.step = 3

      if (!posts.every(({ hasFailed }) => hasFailed)) {
        if (this.isScheduledPost) {
          this.$emit('savedScheduledContent')
        } else {
          this.$emit('contentSaved')
        }
      }
    }
  }
}
</script>

<style>
.dialog-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.social-media-dialog .v-stepper__header{
  box-shadow: none;
}
@media (max-width: 600px) {
  .social-media-dialog{
    border-radius: 0 !important;
    overflow-y: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;

    .v-stepper,
    .v-stepper__items,
    .v-stepper__content,
    .v-stepper__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
