export const SocialMedia = Object.freeze({
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN'
})

export const FacebookStatusType = Object.freeze({
  CONNECTED: 'connected',
  NOT_AUTHORIZED: 'not_authorized',
  UNKNOWN: 'unknown'
})
