<template>
  <v-btn
    class="py-6 justify-start"
    @click="connect"
  >
    <v-img
      :src="KindTypeIcon.linkedinPost"
      width="30"
      height="30"
      class="mr-2 flex-grow-0"
    />
    {{ $t('buttons.social-media.connect-linkedin') }}
  </v-btn>
</template>

<script>
import * as Sentry from '@sentry/vue'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import { SocialMedia } from '../../enums/SocialMedia'
import { KindTypeIcon } from '../../enums/KindType'

const LINKEDIN_CLIENT_ID = process.env.VUE_APP_LINKEDIN_APP_CLIENT_ID

export default {
  props: {
    connectionWrapper: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      KindTypeIcon
    }
  },
  methods: {
    async connect () {
      await this.connectionWrapper('LinkedIn', async () => {
        const authResponse = await this.connectToLinkedin()
        return {
          accessToken: authResponse,
          type: SocialMedia.LINKEDIN,
          redirectUri: this.composeLinkedinAuthRedirectUrl()
        }
      })
    },
    async connectToLinkedin () {
      const target = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${this.composeLinkedinAuthRedirectUrl()}&scope=rw_organization_admin%20r_organization_social%20w_organization_social`
      const authWindow = window.open(target, 'LinkedInAuth', 'width=600,height=600')

      // Promise that resolves when handleLinkedinAuthResponse is called
      const authResponse = await new Promise((resolve, reject) => {
        const handleAuthResponse = (event) => {
          if (event.origin !== window.location.origin) {
            return
          }

          if (event.data?.linkedinConnectionError) {
            Sentry.captureException(new Error(`LinkedIn connection error: ${event.data.linkedinConnectionError.error} - ${event.data.linkedinConnectionError.errorDescription}`))
            showSnackbarMessage('error', this.$t('alerts.social-media.connect-to-platform.error', { platform: 'LinkedIn' }))
            window.removeEventListener('message', handleAuthResponse, false)
            clearInterval(checkWindowClosed)
            reject(new Error('LinkedIn connection error'))
          }

          // Only consider messages from the LinkedIn auth popup (so only with linkedinConnectionCode property)
          if (event.data?.linkedinConnectionCode) {
            window.removeEventListener('message', handleAuthResponse, false)
            clearInterval(checkWindowClosed)
            resolve(event.data.linkedinConnectionCode)
          }
        }

        window.addEventListener('message', handleAuthResponse, false)

        // Check if the auth window is closed
        const checkWindowClosed = setInterval(() => {
          if (authWindow.closed) {
            window.removeEventListener('message', handleAuthResponse, false)
            clearInterval(checkWindowClosed)
            reject(new Error('LinkedIn authentication window closed by user'))
          }
        }, 500)
      })

      return authResponse
    },

    composeLinkedinAuthRedirectUrl () {
      return new URL('linkedin-callback', window.location.origin).toString()
    }
  }
}
</script>
