<template>
  <v-tabs
    v-model="activeSection"
    right
    slider-size="1"
    :disabled="disabled"
    @change="onChange()"
  >
    <v-tab
      v-for="filter in filters"
      :key="filter.value"
      replace
      :to="filter.link"
      :disabled="disabled"
    >
      {{ filter.label }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  props: {
    section: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeSection: this.section
    }
  },
  computed: {
    filters () {
      return [
        { value: 'ALL', label: this.$t('content-creator.tabs.all'), link: '#all' },
        { value: 'TODAY', label: this.$t('content-creator.tabs.today'), link: '#today' },
        { value: 'WEEK', label: this.$t('content-creator.tabs.this-week'), link: '#week' },
        { value: 'MONTH', label: this.$t('content-creator.tabs.this-month'), link: '#month' },
        { value: 'YEAR', label: new Date().getFullYear(), link: '#year' }
      ]
    }
  },
  methods: {
    onChange () {
      this.$emit('sectionChange', this.activeSection)
      this.$emit('refetch')
    }
  }
}
</script>
