import JSZip from 'jszip'
export const createAndDownloadZip = async ({ text, imagePath, textKind, topic, url }) => {
  if (!text && !imagePath) {
    return false
  }
  const zip = new JSZip()

  if (text) {
    zip.file(`${topic} ${textKind} Text.txt`, text)
  }

  if (url) {
    zip.file(`${topic} Link.txt`, url)
  }

  if (imagePath) {
    try {
      const response = await fetch(imagePath)
      const buffer = await response.arrayBuffer()
      if (buffer) {
        zip.file(`${topic} ${textKind} Image.jpeg`, buffer)
      }
    } catch (error) {
      return false
    }
  }

  const blob = await zip.generateAsync({ type: 'blob' })
  const objectUrl = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = objectUrl
  link.setAttribute('download', `${topic} ${textKind}` + '.zip')
  document.body.appendChild(link)
  link.click()
  return true
}
