export const formatDate = (date) => {
  if (!date) return ''

  const dateObj = new Date(date)
  return dateObj.toLocaleDateString('en-US')
}

export const formatDateWithWeekdayName = (date, appLocale) => {
  if (!date) return ''

  const dateObj = new Date(date)
  return dateObj.toLocaleTimeString(appLocale, {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
}

export const formatTime = (date) => {
  if (!date) return ''

  const dateObj = new Date(date)
  return dateObj.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  })
}
