import { FacebookStatusType } from '@/modules/contentCreator/creator/enums/SocialMedia'

export const FB_APP_ID = process.env.VUE_APP_FB_APP_ID

export const initFbSDK = async () => {
  return new Promise((resolve, reject) => {
    window.fbAsyncInit = async () => {
      await window.FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      })

      window.FB.AppEvents.logPageView()
      return resolve(true)
    }

    (function (d, s, id) {
      var js
      var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      js.async = true
      js.crossOrigin = 'anonymous'
      js.defer = true
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  })
}

export const facebookSDKInitialized = () => {
  return window.FB
}

export const loginToFacebook = () => {
  return new Promise((resolve, reject) => {
    window.FB.login((loginResponse) => {
      if (loginResponse && loginResponse.status === FacebookStatusType.CONNECTED) {
        setAccessToken(loginResponse.authResponse.accessToken)
        return resolve(loginResponse)
      }
      return reject(new Error(FacebookStatusType.NOT_AUTHORIZED))
    }, { scope: 'pages_show_list,pages_manage_metadata,pages_manage_posts,pages_read_engagement,business_management' })
  })
}

export const loginToInstagram = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(function (loginResponse) {
      if (loginResponse && loginResponse.status === FacebookStatusType.CONNECTED) {
        return resolve(loginResponse)
      }
      return reject(new Error(FacebookStatusType.NOT_AUTHORIZED))
    }, { scope: 'instagram_basic,instagram_content_publish,business_management', auth_type: 'reauthenticate' })
  })
}

export const logoutFromFacebook = () => {
  return new Promise((resolve, reject) => {
    window.FB.logout((logoutResponse) => {
      if (logoutResponse) {
        setAccessToken(null)
        return resolve(logoutResponse)
      }
      return reject(new Error(FacebookStatusType.NOT_AUTHORIZED))
    })
  })
}

// Should be globally accessible token
let accessToken = null
const setAccessToken = (token) => {
  accessToken = token
}

export const getAccessToken = () => {
  return accessToken
}
