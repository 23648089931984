<template>
  <div class="social-media-dialog text-center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="social-media-dialog d-flex flex-column">
        <v-card-title class="pa-0">
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <span class="delete-post-title">{{ $t('content-creator.delete-post.title') }}</span>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="$emit('close')"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-stepper
          v-model="step"
          alt-labels
          flat
          color="primary"
          elevation="0"
          :class="{ 'delete-success': step === 2}"
        >
          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="stepper"
            >
              <v-card-text class="pa-0 card-text">
                <h4 class="content-title">
                  {{ $t('content-creator.delete-post.content.0') }}
                </h4>
                <span class="content-subtitle">{{ $t('content-creator.delete-post.content.1') }}</span>
                <div
                  v-for="post in socialMediaPosts"
                  :key="post.id"
                  class="d-flex justify-start align-center px-2"
                >
                  <ScheduledPostItem
                    :post="post"
                    :display-checkbox="hasMoreThanOnePost"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-between pa-0 card-actions">
                <v-btn
                  class="px-0 btn close-btn"
                  text
                  @click="$emit('close')"
                >
                  {{ $t('labels.close') }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="px-0 btn scheduled-post-btn"
                  text
                  :loading="loading"
                  :disabled="!hasSelectedPost && hasMoreThanOnePost"
                  @click="deleteSocialMediaPosts()"
                >
                  {{ $t('buttons.content-creator.scheduled-post.delete') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content
              step="2"
              class="py-0"
            >
              <DeleteScheduledPostSuccess @close="$emit('close')" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import DELETE_SAVED_CONTENT from '@/modules/contentCreator/creator/queries/deleteSavedContent.gql'
import DELETE_SOCIAL_MEDIA_POSTS from '@/modules/contentCreator/creator/queries/deleteSocialMediaPosts.gql'
import DeleteScheduledPostSuccess from '@/modules/contentCreator/creator/SocialMedia/DeleteScheduledPostSuccess.vue'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import ScheduledPostItem from '@/modules/contentCreator/creator/SocialMedia/ScheduledPostItem.vue'
export default {
  components: { ScheduledPostItem, DeleteScheduledPostSuccess },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      socialMediaPosts: this.content.socialMediaPosts.map(post => {
        return {
          ...post,
          selected: this.content.socialMediaPosts.length <= 1
        }
      }),
      dialog: true,
      loading: false,
      step: 1
    }
  },
  computed: {
    hasMoreThanOnePost () {
      return this.content.socialMediaPosts.length > 1
    },
    hasSelectedPost () {
      return this.socialMediaPosts.some(post => post.selected)
    },
    allPostsSelected () {
      return this.socialMediaPosts.every(post => post.selected)
    }
  },
  methods: {
    async deleteSocialMediaPosts () {
      if (!this.hasSelectedPost) {
        return
      }

      this.loading = true
      const selectedPosts = this.socialMediaPosts.filter(post => post.selected)

      try {
        await this.$apollo.mutate({
          mutation: DELETE_SOCIAL_MEDIA_POSTS,
          variables: {
            input: {
              ids: selectedPosts.map(post => post.id)
            }
          }
        })
        this.$tracking.event('Content Creator', 'Edited', 'Deleted Social Media Posts')

        if (this.allPostsSelected) {
          await this.deleteSavedContent()
        }

        this.step = 2
        this.$emit('postDeleted')

        setTimeout(() => {
          this.$emit('close')
        }, 5000)
      } catch (err) {
        showSnackbarMessage('error', this.$t('social-media.alert.post-delete-error'))
      } finally {
        this.loading = false
      }
    },

    async deleteSavedContent () {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_SAVED_CONTENT,
          variables: {
            input: {
              id: this.content.id
            }
          }
        })
      } catch (err) {
        showSnackbarMessage('error', this.$t('social-media.alert.saved-content-delete-error'))
      }
    }
  }
}
</script>

<style>

.stepper {
  padding: 24px;
}
@media (max-width: 600px) {
  .stepper {
    padding: 16px;
  }
  .btn {
    width: 50%;
  }

  .delete-success {
    height: 100vh;
    align-content: center;
  }
}
.card-actions {
  margin-top: 24px;
}
.delete-post-title {
  color: #FFFFFF;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.content-title {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.content-subtitle {
  color: #414142;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.scheduled-post-btn, .close-btn {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

</style>
