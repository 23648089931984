import { KindName } from '@/modules/contentCreator/creator/enums/KindType'
import setQueryParameters from '@/lib/setQueryParameters'

export const buildUrlWithTracking = (url, kind, imageSource) => {
  if (!url) return null

  const trackingParams = {
    utm_source: (KindName[kind] || '').toLowerCase(),
    utm_medium: 'post',
    utm_content: `${imageSource.toLowerCase()}-image`
  }

  return setQueryParameters(url, trackingParams)
}
